<template>
  <div id="bigScreen" v-if="refresh" :class="{fullScreen:isFullScreen}">
    <div class="bigScreen-wrap">
      <Map ref="map" :isFullScreen="isFullScreen"/>
      <div class="fullScreenBtn" @click="changeFullScreen">
        <img v-if="isFullScreen" src="../../assets/images/bigScreen/arrowleft.png" alt="">
        <img v-else src="../../assets/images/bigScreen/enlarge.png" alt="">
        <span>{{ isFullScreen ? '返回' : "全屏" }}</span>
      </div>
      <bigScreen-filter v-if="isPackingSelect" />
      <div class="title">
        <img src="../../assets/images/logo2.png" alt="">
        <span>点点行行停车系统管理平台</span>
      </div>
      <div class="bigScreen-container">
        <div class="left">
          <sales v-if="tabList.includes('/bigScreen/sales')"/>
          <salesStatistics v-if="tabList.includes('/bigScreen/salesStatistics')"/>
          <parkingRecord v-if="tabList.includes('/bigScreen/parkingRecord')"/>
        </div>
        <div class="right">
          <parkingInfo @parkingLotClick="parkingLotClick" v-if="tabList.includes('/bigScreen/parkingInfo')"/>
          <paymentMethod v-if="tabList.includes('/bigScreen/paymentMethod')"/>
          <LeasedCarsPercentage v-if="tabList.includes('/bigScreen/LeasedCarsPercentage')"/>
        </div>
      </div>
      <!--      <div class="shadow" :class="{'shadow-fullScreen':isFullScreen}"></div>-->
      <!--      <createSocket />-->
    </div>
  </div>
</template>

<script>
import {toFullScreen, exitFullscreen} from "@/utils/fullScreen";

export default {
  components: {
    bigScreenFilter: () => import("./filter"),
    sales: () => import("./sales"),
    salesStatistics: () => import("./salesStatistics"),
    parkingRecord: () => import("./parkingRecord"),
    parkingInfo: () => import("./parkingInfo"),
    Map: () => import("./map"),
    paymentMethod: () => import("./paymentMethod"),
    LeasedCarsPercentage: () => import("./LeasedCarsPercentage"),
    initSocket: () => import("@/components/initSocket"),
  },
  data() {
    return {
      isFullScreen: false,
      refresh: true,
    }
  },
  computed:{
    isPackingSelect() {
      return this.$store.state.user?.authorities.includes("WEB_ADMIN");
    },
    tabList() {
      return this.$store.state.controlAuthority.tabList;
    },
  },
  mounted() {
    document.addEventListener("webkitfullscreenchange", this.webkitfullscreenchange);
  }
  ,
  destroyed() {
    document.removeEventListener("webkitfullscreenchange", this.webkitfullscreenchange);
  }
  ,
  methods: {
    webkitfullscreenchange(e) {
      if (!document.webkitIsFullScreen) {
        this.isFullScreen = false
        this.refreshPage()
      }
    }
    ,
    changeFullScreen() {
      this.isFullScreen = !this.isFullScreen
      this.refreshPage()
      if (this.isFullScreen) {
        toFullScreen()
      } else {
        exitFullscreen()
      }
    }
    ,
    refreshPage() {
      this.refresh = false
      setTimeout(() => {
        this.refresh = true
      })
    }
    ,
    parkingLotClick(parkingLotId) {
      this.$refs.map.markerHandleClick(parkingLotId)
    }
  }
}
</script>

<style lang="scss">
#bigScreen {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.fullScreen {
    width: 100vw;
    height: 100vh;
    padding: 0.77rem 1.49rem;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .bigScreen-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .shadow {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      background: radial-gradient(rgba(255, 255, 255, .1), rgba(0, 0, 0, 1));
    }

    .shadow-fullScreen {
      width: 100vw;
      height: 100vh;
    }

    .fullScreenBtn {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      width: 6.13rem;
      height: 2.44rem;
      text-align: center;
      background: url("../../assets/images/bigScreen/back.png") no-repeat center center;
      background-size: contain;
      font-size: 1.25rem;
      color: white;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.77rem 0 0.77rem 1.49rem;

      img {
        height: 60%;
        margin-right: 0.31rem;
      }
    }

    .title {
      position: relative;
      z-index: 2;
      width: 86%;
      height: 3.66rem;
      line-height: 3.66rem;
      background: url("../../assets/images/bigScreen/title.png") no-repeat center center;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 0.5rem;
        height: 50%;
      }

      span {

        color: white;
        font-size: 2.2rem;
      }
    }

    .bigScreen-container {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      margin-top: 2.35rem;

      h2 {
        letter-spacing: 0.25rem;
        height: 2.37rem;
        line-height: 2.6rem;
        background: url("../../assets/images/bigScreen/title2.png") no-repeat center center;
        background-size: contain;
        text-align: center;
        color: white;
        font-size: 1.25rem;
        font-weight: initial;
        margin-bottom: 1.5rem;
      }

      .left {
        position: relative;
        z-index: 10;
        width: 22.47rem;
        height: calc(100vh - 7rem);
      }

      .right {
        position: relative;
        z-index: 10;
        width: 27.11rem;
        height: calc(100vh - 7rem);
      }
    }
  }
}
</style>
